
import i18n from 'i18next'
import { getCurrency } from './helpers'

export function onProductClick(accessory) {
    try {
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
            event: "select_item",
            ecommerce: {
                items: [{
                    item_name: accessory.name,
                    item_id: accessory.id,
                    index: 1,
                    price: accessory.price / 100
                }]
            }
        })
    } catch (e) {
        console.error(e)
    }
}

export function onProductDetailsClick(product) {
    try {
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
            event: "view_item",
            ecommerce: {
                items: [{
                    item_name: product.name,
                    item_id: product.id,
                    price: product.price / 100
                }]
            }
        })
    } catch (e) {
        console.error(e)
    }
}

export function onCheckout(order, product, amount) {
    try {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: { items: getOrderItems(order, product, amount) }
        });
    } catch (e) {
        console.error(e)
    }
}

export function onPurchase(orderID, order, product, amount) {
    try {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: "purchase",
            ecommerce: {
                transaction_id: orderID,
                value: amount.total + '',
                currency: getCurrency(i18n.language, true),
                items: getOrderItems(order, product, amount)
            }
        });
    } catch (e) {
        console.error(e)
    }
}


function getOrderItems(order, product, amount) {
    const items = [{
        item_name: product.name,
        item_id: product.id,
        price: product.price / 100,
        index: 1,
        quantity: order.count
    }]

    if (order.createPrintDesign) {
        items.push({
            item_name: "З індивідуальним друком",
            item_id: "print-design",
            price: amount.printTypeDesign,
            index: 2,
            quantity: 1
        })
    }

    if (order.enableCustomPageDesign) {
        items.push({
            item_name: "Індивідуальний дизайн сторінки",
            item_id: "custom-page-design",
            price: amount.pageDesign,
            index: 3,
            quantity: 1
        })
    }

    return items
}